<template>
  <Teleport to="body">
    <div class="modal order-popup__root">
      <div class="modal__body ">
        <button
          class="modal__close"
          @click="onCloseProductPopup"
        >
          <img
            src="~/assets/img/svg/popup/close.svg"
            alt="close icon"
          >
        </button>
        <div class="modal-content">
          <div class="modal-content-info">
            <div class="content-title">{{ currentProduct?.name }}</div>
            <div
              class="content-text"
              v-html="currentProduct?.description"
            ></div>
          </div>
          <div class="modal-img">
            <div class="imgWrapper">
              <img
                src="~/assets/img/svg/popup/vertical-separator.svg"
                alt="separator"
                class="vert-separator"
              >
              <img
                src="~/assets/img/svg/popup/horizontal-separator.svg"
                alt="separator"
                class="horiz-separator"
              >
              <div class="adaptive-img nuxtImgWrapper">
                <NuxtImg
                  loading="lazy"
                  width="117"
                  height="254"
                  :src="String(currentProduct?.image_id)"
                  class="work-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal__overlay"
        @click="onCloseProductPopup"
      ></div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { useProductPopup } from '~/composables/useProductPopup';

const { onCloseProductPopup, currentProduct } = useProductPopup();

</script>

<style scoped lang="scss">
.order-popup__root{
  .modal__body{
    max-width: 790px;
    top: 50%;
    transform: translateY(-50%);
    overflow: unset;
    padding: 0;
  }
}

.modal__close {
  top: 0;
  right: -51px;
  width: 36px;
  height: 36px;
}

.modal__overlay {
  opacity: 0.8;
  background: #2E5300;
}

.modal-content-info {
  padding: 32px 0 32px 32px;
}

.content-title {
  color: var(--dark-gray);
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.6px;
  margin-bottom: 16px;
}

.modal-content {
  display: flex;
  gap: 40px;
}

.modal-img {
  max-width: 320px;
  width: 100%;
  background: #F6F8FA;
  min-height: 398px;
  border-radius: 0 10px 10px 0;
}

.imgWrapper {
  position: relative;
  height: 100%;

  & > .vert-separator {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }

  & > .horiz-separator {
    display: none;
  }
}

.nuxtImgWrapper {
  position: relative;
  height: 100%;

  & > img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 117px;
  }
}

@media (max-width: 640px) {
  .order-popup__root .modal__body {
    top: 48px;
    transform: unset;
  }

  .modal__close {
    top: -46px;
    left: 50%;
    width: 36px;
    height: 36px;
    transform: translateX(-50%);
  }

  .modal-content {
    flex-direction: column-reverse;
    gap: 18px;
  }

  .modal-content-info {
    padding: 0 16px 16px;
  }

  .modal-img {
    max-width: unset;
    min-height: 290px;
    border-radius: 10px 10px 0 0;
  }

  .imgWrapper {
    position: relative;
    height: inherit;
    width: 100%;

    & > .vert-separator {
      display: none;
    }

    & > .horiz-separator {
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
    }
  }

  .nuxtImgWrapper {
    min-height: 290px;

    img {
      object-fit: none;
    }
  }
}
</style>

<style lang="scss">
.content-text * {
  font-family: Uniform-Pro, sans-serif;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.3px;
}
</style>
